// Slideshow
// Author: Touchet Laurent (touchetlaurent.fr) for Lestuileries.fr

function initSlideshow(container) {

    var app = {};

    // Properties
    var defaults = {
        delay: 3000
    };

    // Container divs
    var slides = container.children;
    var currentSlide = slides[0];

    setInterval(function() {

        currentSlide.classList.remove('active');

        if (currentSlide.nextElementSibling) {
            currentSlide = currentSlide.nextElementSibling;
        } else {
            currentSlide = slides[0];
        }

        currentSlide.classList.add('active');

    }, defaults.delay);
}
