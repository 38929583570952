(function() {

    // toggle MainMenu
    document.getElementById('toggleMenuContainer').addEventListener('click', function() {
        this.classList.toggle('open');
        document.getElementById('mainMenu').classList.toggle('open');
    });

    var footerSlideshow = document.getElementById('footer-slideshow');
    initSlideshow(footerSlideshow);

})();
